<template>
    <v-container fluid>
        <v-row fill-height>
            <v-col cols="7" fill-height>
                <v-card v-if="!loading" class="ma-n3">
                    <!-- {{ informant }} -->
                    <!-- {{ incident.data.InformantDetails }} -->
                    <v-hover v-if="true" v-slot="{ hover }">
                    <!-- <v-card :to="{name:'Informant', params:{'entrycode': entrycode}}" elevation=0 v-if="informant"> -->
                    <v-card @click="informantlink" elevation=0 v-if="informant">
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Informant Details
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="informant === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="informant.nameAndSurname"><b>Name & Surname:</b> {{ informant.nameAndSurname }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="informant.occupation"><b>Informer's occupation:</b> {{ informant.occupation }}</v-list-item-title>
                                <v-list-item-title v-if="informant.agencyOrganization"><b>Agency/Organization:</b> {{ informant.agencyOrganization }}</v-list-item-title>
                                <v-list-item-title v-if="informant.townOrVillage"><b>Town/village:</b> {{ informant.townOrVillage }}</v-list-item-title>
                                <v-list-item-title v-if="informant.phone"><b>Phone:</b> {{ informant.phone }}</v-list-item-title>
                                <v-list-item-title v-if="informant.email"><b>Email:</b> {{ informant.email }}</v-list-item-title>
                                <v-list-item-title v-if="informant.extraInformation"><b>Other source of information:</b> {{ informant.extraInformation }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ notification }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="notificationlink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Notification of poison event
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="notification === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="notification.notificationDay"><b>Date of notification:</b> {{ notification.notificationDay }}</v-list-item-title>
                                <v-list-item-title v-if="notification.eventDate"><b>Date of event:</b> {{ notification.eventDate }}</v-list-item-title>
                                <v-list-item-title v-if="notification.eventDiscoveredWhile"><b>Discovered While:</b> {{ notification.eventDiscoveredWhile }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ locinfo }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="poisonlink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Poison event's location
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="locinfo === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="locinfo.country"><b>Country:</b> {{ locinfo.country }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.region"><b>Region:</b> {{ locinfo.region }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.county"><b>County:</b> {{ locinfo.county }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.municipality"><b>Municipality:</b> {{ locinfo.municipality }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.municipalityDistrict"><b>Municipality or District:</b> {{ locinfo.municipalityDistrict }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.locality"><b>Village and/or locality:</b> {{ locinfo.locality }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.lat"><b>Latitude:</b> {{ locinfo.lat }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.lng"><b>Longitude:</b> {{ locinfo.lng }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.urbanIncident"><b>Urban ncident:</b> {{ locinfo.urbanIncident }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.privOrPubLand"><b>Private or public land:</b> {{ locinfo.privOrPubLand }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.privOrPubHuntingReserve"><b>Hunting reserve:</b> {{ locinfo.privOrPubHuntingReserve }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.areaProtectionStatus1"><b>Area's protection status 1:</b> {{ locinfo.areaProtectionStatus1 }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.areaProtectionStatus2"><b>Area's protection status 2:</b> {{ locinfo.areaProtectionStatus2 }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.areaProtectionStatus3"><b>Area's protection status 3:</b> {{ locinfo.areaProtectionStatus3 }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.areaProtectionStatus4"><b>Area's protection status 4:</b> {{ locinfo.areaProtectionStatus4 }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.primaryHabitat"><b>Primary habitat (Corine code):</b> {{ locinfo.primaryHabitat }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.weatherPreviousDays"><b>Weather during previous days:</b> {{ locinfo.weatherPreviousDays }}</v-list-item-title>
                                <v-list-item-title v-if="locinfo.extraInfo"><b>Other information:</b> {{ locinfo.extraInfo }}</v-list-item-title>
                    
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ danimal }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="deadanimallink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Dead Animal Found
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="danimal === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="danimal.deadAnimalFound"><b>Dead animal found:</b> {{ danimal.deadAnimalFound }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="danimal.numberOfDeadAnimals"><b>Total number dead animals found:</b> {{ danimal.numberOfDeadAnimals }}</v-list-item-title>
                                <br/>
                                <template v-for="(animal, i) in danimal.deadanimalinfopages">
                                    <v-list-item-title v-if="animal.number" :key="`num-${i}`"><b>Amount:</b> {{ animal.number }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.species" :key="`ide-${i}`"><b>Animal species:</b> {{ animal.species }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.notListedSpecies" :key="`mark-${i}`"><b>Specified:</b> {{ animal.notListedSpecies }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.marking" :key="`mrk-${i}`"><b>Identification marking:</b> {{ animal.marking }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.markingType" :key="`mrkt-${i}`"><b>Type of marking:</b> {{ animal.markingType }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.markingCodes" :key="`mrkc-${i}`"><b>Marking codes:</b> {{ animal.markingCodes }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.lat" :key="`lat-${i}`"><b>Lat:</b> {{ animal.lat }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.lng" :key="`lng-${i}`"><b>Lng:</b> {{ animal.lng }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.carcass" :key="`car-${i}`"><b>StateCarcass:</b> {{ animal.carcass }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.comments" :key="`com-${i}`"><b>Comments:</b> {{ animal.comments }}</v-list-item-title>
                                    <br/>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>


                    <!-- {{ alanimal }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="aliveanimallink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Alive Animal Found
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="alanimal === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="alanimal.aliveAnimalFound"><b>Alive animal found:</b> {{ alanimal.aliveAnimalFound }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="alanimal.numberOfAliveAnimals"><b>Total number alive animals found:</b> {{ alanimal.numberOfAliveAnimals }}</v-list-item-title>
                                <br/>
                                <template v-for="(animal, i) in alanimal.aliveanimalinfopages">
                                    <v-list-item-title v-if="animal.number" :key="`num-${i}`"><b>Amount:</b> {{ animal.number }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.species" :key="`ide-${i}`"><b>Animal species:</b> {{ animal.species }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.notListedSpecies" :key="`mark-${i}`"><b>Specified:</b> {{ animal.notListedSpecies }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.marking" :key="`mrk-${i}`"><b>Identification marking:</b> {{ animal.marking }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.markingType" :key="`mrkt-${i}`"><b>Type of marking:</b> {{ animal.markingType }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.markingCodes" :key="`mrkc-${i}`"><b>Marking codes:</b> {{ animal.markingCodes }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.lat" :key="`lat-${i}`"><b>Lat:</b> {{ animal.lat }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.lng" :key="`lng-${i}`"><b>Lng:</b> {{ animal.lng }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.animalState" :key="`car-${i}`"><b>State of animal:</b> {{ animal.animalState }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.firstAidType" :key="`com-${i}`"><b>First aid provided:</b> {{ animal.firstAidType }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.firstAidProvider" :key="`com-${i}`"><b>First aid provided by:</b> {{ animal.firstAidProvider }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.nameRehabilitationCenter" :key="`com-${i}`"><b>Sent to rehabilitation center (name):</b> {{ animal.nameRehabilitationCenter }}</v-list-item-title>
                                    <v-list-item-title v-if="animal.finalStatus" :key="`com-${i}`"><b>Final status:</b> {{ animal.finalStatus }}</v-list-item-title>
                                    <br/>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ bait }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="poisonbaitlink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Poison Bait Info
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="bait === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="bait.poisonBaitFound"><b>Poison bait found:</b> {{ bait.poisonBaitFound }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="bait.number"><b>Number:</b> {{ bait.number }}</v-list-item-title>
                                <v-list-item-title v-if="bait.comments"><b>Comments:</b> {{ bait.comments }}</v-list-item-title>
                                <br/>
                                <template v-for="(bt, i) in bait.poisonbaitinfopages">
                                    <v-list-item-title v-if="bt.number" :key="`bnum-${i}`"><b>Amount:</b> {{ bt.number }}</v-list-item-title>
                                    <v-list-item-title v-if="bt.baitType" :key="`bait-${i}`"><b>Bait type:</b> {{ bt.baitType }}</v-list-item-title>
                                    <v-list-item-title v-if="bt.baitOther" :key="`baitother-${i}`"><b>Other bait type:</b> {{ bt.baitOther }}</v-list-item-title>
                                    <v-list-item-title v-if="bt.lat" :key="`btlat-${i}`"><b>Lat:</b> {{ bt.lat }}</v-list-item-title>
                                    <v-list-item-title v-if="bt.lng" :key="`btlng-${i}`"><b>Long:</b> {{ bt.lng }}</v-list-item-title>
                                    <v-list-item-title v-if="bt.codeAnalysis" :key="`bcode-${i}`"><b>Code for analysis:</b> {{ bt.codeAnalysis }}</v-list-item-title>
                                    <br/>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>
                    
                    <!-- {{ evidence }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="evidencelink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Evidence Info
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="evidence === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="evidence.otherEvidenceFound"><b>Other evidence found:</b> {{ evidence.otherEvidenceFound }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="evidence.totalNumberOfEvidence"><b>Total number of evidence:</b> {{ evidence.totalNumberOfEvidence }}</v-list-item-title>
                                <v-list-item-title v-if="evidence.comments"><b>Comments:</b> {{ evidence.comments }}</v-list-item-title>
                                <br/>
                                <template v-for="(evid, i) in evidence.evidenceinfopages">
                                    <v-list-item-title v-if="evid.number" :key="`evnum-${i}`"><b>Amount:</b> {{ evid.number }}</v-list-item-title>
                                    <v-list-item-title v-if="evid.evidenceItem" :key="`evitem-${i}`"><b>Evidence item:</b> {{ evid.evidenceItem }}</v-list-item-title>
                                    <v-list-item-title v-if="evid.evidenceItemOther" :key="`evother-${i}`"><b>Other evidence:</b> {{ evid.evidenceItemOther }}</v-list-item-title>
                                    <v-list-item-title v-if="evid.lat" :key="`evlat-${i}`"><b>Lat:</b> {{ evid.lat }}</v-list-item-title>
                                    <v-list-item-title v-if="evid.lng" :key="`evlng-${i}`"><b>Lng:</b> {{ evid.lng }}</v-list-item-title>
                                    <v-list-item-title v-if="evid.sampleCode" :key="`evsample-${i}`"><b>Sample code:</b> {{ evid.sampleCode }}</v-list-item-title>
                                    <br/>
                                </template>
                                
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ motive }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="motivelink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Motive
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="motive === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="motive.suspectedReason"><b>Suspected reason:</b> {{ motive.suspectedReason }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="motive.suspectedTarget"><b>Suspected target:</b> {{ motive.suspectedTarget }}</v-list-item-title>
                                <v-list-item-title v-if="motive.details"><b>Details:</b> {{ motive.details }}</v-list-item-title>
                                <v-list-item-title v-if="motive.suspects"><b>Suspects:</b> {{ motive.suspects }}</v-list-item-title>
                                <v-list-item-title v-if="motive.confirmedReason"><b>Confirmed reason:</b> {{ motive.confirmedReason }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ authority }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="authoritylink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Authorities involved in Manage of Event
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="authority === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="authority.publicAuthoritiesContacted"><b>Public authorities contacted:</b> {{ authority.publicAuthoritiesContacted }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="authority.numberAuthorities"><b>Number of authorities:</b> {{ authority.numberAuthorities }}</v-list-item-title>
                                <template v-for="(auth, i) in authority.authoritiespages">
                                    <v-list-item-title v-if="auth.number" :key="`authnum-${i}`"><b>Number:</b> {{ auth.number }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.agency" :key="`authage-${i}`"><b>Agency:</b> {{ auth.agency }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.timeNotified" :key="`authtime-${i}`"><b>Time notified:</b> {{ auth.timeNotified }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.presentAtManagement" :key="`authpre-${i}`"><b>Present at management:</b> {{ auth.presentAtManagement }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.reason" :key="`autrea-${i}`"><b>Specify reason if not present:</b> {{ auth.reason }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.nameSurnameContact" :key="`authnsur-${i}`"><b>Name and surname of contact:</b> {{ auth.nameSurnameContact }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.position" :key="`authpos-${i}`"><b>Position:</b> {{ auth.position }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.phone" :key="`authpohe-${i}`"><b>Phone:</b> {{ auth.phone }}</v-list-item-title>
                                    <v-list-item-title v-if="auth.email" :key="`authem-${i}`"><b>Email:</b> {{ auth.email }}</v-list-item-title>
                                    <br/>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ antipoison }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="antidoglink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Antipoison Dog Unit
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="antipoison === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="antipoison.antipoisonDogUsed"><b>Antipoison dog used:</b> {{ antipoison.antipoisonDogUsed }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="antipoison.antipoisonDogId"><b>Antipoison dog ID:</b> {{ antipoison.antipoisonDogId }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ samples }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card :to="{name:'SamplesCol', params:{'entrycode': entrycode}}" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Samples and/or evidence collected
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="samples === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="samples.numberOfSamplesCollected"><b>Number of samples and evidence collected:</b> {{ samples.numberOfSamplesCollected }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <template v-for="(sam, i) in samples.samplecollectedinfopages">
                                    <v-list-item-title v-if="sam.number" :key="`sanum-${i}`"><b>Amount:</b> {{ sam.number }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.sampleEvidenceCode" :key="`saevid-${i}`"><b>Sample/Evidence code:</b> {{ sam.sampleEvidenceCode }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.storedIn" :key="`sastor-${i}`"><b>Stored in:</b> {{ sam.storedIn }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.collectedBy" :key="`samcolby-${i}`"><b>Collected by:</b> {{ sam.collectedBy }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.collectedOther" :key="`sacoloth-${i}`"><b>Collected by, other:</b> {{ sam.collectedOther }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.storedBy" :key="`samstoby-${i}`"><b>Stored by:</b> {{ sam.storedBy }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.storedOther" :key="`samsooth-${i}`"><b>Stored by, other:</b> {{ sam.storedOther }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.storingPlace" :key="`samstopl-${i}`"><b>Storing place:</b> {{ sam.storingPlace }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.sentTo" :key="`samsento-${i}`"><b>Sent to:</b> {{ sam.sentTo }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.sentToOther" :key="`samseo-${i}`"><b>Sent to, other:</b> {{ sam.sentToOther }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.dateSent" :key="`samdat-${i}`"><b>Date sent:</b> {{ sam.dateSent }}</v-list-item-title>
                                    <v-list-item-title v-if="sam.results" :key="`samre-${i}`"><b>Results:</b> {{ sam.results }}</v-list-item-title>
                                    <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                    <br/>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ disposal }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card :to="{name:'DisposalDead', params:{'entrycode': entrycode}}" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Disposal of Dead Animals
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="disposal === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="disposal.numberOfAnimals"><b>Number of animals:</b> {{ disposal.numberOfAnimals }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="disposal.disposalMethod"><b>Disposal method:</b> {{ disposal.disposalMethod }}</v-list-item-title>
                                <v-list-item-title v-if="disposal.disposedAtLocIndByAuthorities"><b>Disposed at location indicated by authorities:</b> {{ disposal.disposedAtLocIndByAuthorities }}</v-list-item-title>
                                <v-list-item-title v-if="disposal.location"><b>Location:</b> {{ disposal.location }}</v-list-item-title>
                                <v-list-item-title v-if="disposal.disposedBy"><b>Disposed by:</b> {{ disposal.disposedBy }}</v-list-item-title>
                                <v-list-item-title v-if="disposal.takenByAuthToBeDisposed"><b>Taken by authorities to be disposed of:</b> {{ disposal.takenByAuthToBeDisposed }}</v-list-item-title>
                                <v-list-item-title v-if="disposal.takenByHiredCompany"><b>Taken by hired company:</b> {{ disposal.takenByHiredCompany }}</v-list-item-title>
                                <v-list-item-title v-if="disposal.companyName"><b>Company name:</b> {{ disposal.companyName }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>
                    
                    <!-- {{ otherdeath }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="otherreasonslink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Other Possible Death Reasons
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="otherdeath === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="otherdeath.suspectedCause"><b>Suspected cause:</b> {{ otherdeath.suspectedCause }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="otherdeath.extraDetails"><b>Extra details:</b> {{ otherdeath.extraDetails }}</v-list-item-title>
                                <v-list-item-title v-if="otherdeath.samplesForAnalysisRabies"><b>If rabies applicable, sample sent for analysis?:</b> {{ otherdeath.samplesForAnalysisRabies }}</v-list-item-title>
                                <v-list-item-title v-if="otherdeath.rabiesLab"><b>Rabies lab:</b> {{ otherdeath.rabiesLab }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ complaint }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="officialcomplink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Official Complaint Info
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="complaint === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="complaint.notificationDay"><b>Complaint:</b> {{ complaint.notificationDay }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="complaint.ifNotWhy"><b>If not why:</b> {{ complaint.ifNotWhy }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.dateComplaint"><b>Date of complaint:</b> {{ complaint.dateComplaint }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.authority"><b>Authority:</b> {{ complaint.authority }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.investCarriedOut"><b>Investigation carried out:</b> {{ complaint.investCarriedOut }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.authoritiesInInvestigation"><b>Authorities involved in investigation:</b> {{ complaint.authoritiesInInvestigation }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.caseTakenToCourt"><b>Case taken to court:</b> {{ complaint.caseTakenToCourt }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.dateOfCourt"><b>Date of court:</b> {{ complaint.dateOfCourt }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.caseSolved"><b>Case solved:</b> {{ complaint.caseSolved }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.sentence"><b>Sentence:</b> {{ complaint.sentence }}</v-list-item-title>
                                <v-list-item-title v-if="complaint.comments"><b>Comments:</b> {{ complaint.comments }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ autopsy }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="autopsylink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Autopsy and Toxicological Analysis
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="autopsy === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="autopsy.carriedOut"><b>Carried out:</b> {{ autopsy.carriedOut }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="autopsy.autopsyLab"><b>Autopsy laboratory:</b> {{ autopsy.autopsyLab }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.toxicologicalLab"><b>Toxicological laboratory:</b> {{ autopsy.toxicologicalLab }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.vetInCharge"><b>Vet in charge:</b> {{ autopsy.vetInCharge }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.samplesAnalyzed"><b>Samples analyzed (codes):</b> {{ autopsy.samplesAnalyzed }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.dateOfReport"><b>Date of report:</b> {{ autopsy.dateOfReport }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.companyName"><b>Company name:</b> {{ autopsy.companyName }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.reportCode"><b>Report code:</b> {{ autopsy.reportCode }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.diagnose"><b>Diagnose:</b> {{ autopsy.diagnose }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.numberOfToxin"><b>Number of toxin:</b> {{ autopsy.numberOfToxin }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.presenceOfOtherSubstances"><b>Presence of other substances:</b> {{ autopsy.presenceOfOtherSubstances }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.otherInfo"><b>Other information:</b> {{ autopsy.otherInfo }}</v-list-item-title>
                                <v-list-item-title v-if="autopsy.comments"><b>Comments:</b> {{ autopsy.comments }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ toxinsused }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="toxinslink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Toxins Used
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="toxinsused === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="toxinsused.typeOfPoison"><b>Poison type:</b> {{ toxinsused.typeOfPoison }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                                <v-list-item-title v-if="toxinsused.toxin"><b>Toxin:</b> {{ toxinsused.toxin }}</v-list-item-title>
                                <v-list-item-title v-if="toxinsused.group"><b>Toxin group:</b> {{ toxinsused.group }}</v-list-item-title>
                                <v-list-item-title v-if="toxinsused.legalStatus"><b>Legal status:</b> {{ toxinsused.legalStatus }}</v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ gencom }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="gencommentslink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            General Comments
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="gencom === {}">Not available data</p>
                            <v-list>
                                <v-list-item-title v-if="gencom.comments"><b>General comments:</b> {{ gencom.comments }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ extra }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="extrainfolink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Extra Public Info
                            <v-spacer/>
                            <v-icon v-if="hover && countrycheck">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p v-if="extra === {}">Not avaible data</p>
                            <v-list>
                                <v-list-item-title v-if="extra.newslinks"><b>News links:</b> {{ extra.newslinks }}</v-list-item-title>
                                <v-list-item-title v-else><i>No info available</i></v-list-item-title>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                    <!-- {{ images }} -->
                    <v-hover v-slot="{ hover }">
                    <v-card @click="imageslink" elevation=0>
                        <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">
                            Images
                            <v-spacer />
                            <v-icon v-if="hover && images">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col v-for="image in images" :key="image.id" cols="12" md="4">
                                    <v-img :src="image.thumb" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    </v-hover>

                </v-card>
            </v-col>
            <v-col cols="5">
                <v-row>
                    <incident-info-view />
                    <map-component :key="entrycode"/>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { get } from 'vuex-pathify'
import { mapGetters } from 'vuex'
import PassEntryCode from '@/mixins/PassEntrycode.js'
import MapComponent from '../components/MapComponent.vue'
import IncidentInfoView from '../components/IncidentInfoView.vue'

export default{
  components: { MapComponent, IncidentInfoView },
    mixins: [PassEntryCode],
    data () {
        return {
            foodit: false
        }
    },
    computed: {
        ...mapGetters({
            getIncident: "incidentsnew/getIncidentByEntryCode",
            loading: "incidentsnew/indexLoading"
        }),
        superadmin: get('newauth@userProfile.is_superuser'),
        usercountry: get('newauth@userProfile.country'),
        countrycheck () {
            if (this.superadmin) return true
            if (this.usercountry === this.incident.country) {
                return true
            } else {
                return false
            }
        },
        incident () {
            return this.getIncident(this.entrycode)
        },
        informant () {           
            let _informant = {}
            if ('InformantDetails' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.InformantDetails)) {
                    if (value !== "nan") {
                        _informant[key] = value
                    }
                }
            }
            return _informant
        },
        notification () {
            let _notification = {}
            if ('NotificationInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.NotificationInfo)) {
                    if (value !== "nan") {
                        _notification[key] = value
                    }
                }
            }
            return _notification
        },
        locinfo () {
            let _locinfo = {}
            if ('PoisonLocationInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.PoisonLocationInfo)) {
                    if (value !== "nan") {
                        _locinfo[key] = value
                    }
                }
            }
            return _locinfo
        },
        danimal () {
            let _danimal = {}
            if ('DeadAnimalInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.DeadAnimalInfo)) {
                    if (value !== "nan") {
                        _danimal[key] = value
                    }
                }
            }
            return _danimal
        },
        alanimal () {
            let _alanimal = {}
            if ('AliveAnimalInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.AliveAnimalInfo)) {
                    if (value !== "nan") {
                        _alanimal[key] = value
                    }
                }
            }
            return _alanimal
        },
        bait () {
            let _bait = {}
            if ('PoisonBaitInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.PoisonBaitInfo)) {
                    if (value !== "nan") {
                        _bait[key] = value
                    }
                }
            }
            return _bait
        },
        evidence () {
            let _evidence = {}
            if ('EvidenceInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.EvidenceInfo)) {
                    if (value !== "nan") {
                        _evidence[key] = value
                    }
                }
            }
            return _evidence
        },
        motive () {
            let _motive = {}
            if ('Motive' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.Motive)) {
                    if (value !== "nan") {
                        _motive[key] = value
                    }
                }
            }
            return _motive
        },
        authority () {
            let _authority = {}
            if ('AuthoritiesManageOfEvent' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.AuthoritiesManageOfEvent)) {
                    if (value !== "nan") {
                        _authority[key] = value
                    }
                }
            }
            return _authority
        },
        antipoison () {
            let _antipoison = {}
            if ('AntipoisonDogUnit' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.AntipoisonDogUnit)) {
                    if (value !== "nan") {
                        _antipoison[key] = value
                    }
                }
            }
            return _antipoison
        },
        samples () {
            let _samples = {}
            if ('SampleCollectedInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.SampleCollectedInfo)) {
                    if (value !== "nan") {
                        _samples[key] = value
                    }
                }
            }
            return _samples
        },
        disposal () {
            let _disposal = {}
            if ('DisposalOfDeadAnimals' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.DisposalOfDeadAnimals)) {
                    if (value !== "nan") {
                        _disposal[key] = value
                    }
                }
            }
            return _disposal
        },
        otherdeath () {
            let _otherdeath = {}
            if ('OtherPossibleDeathReasons' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.OtherPossibleDeathReasons)) {
                    if (value !== "nan") {
                        _otherdeath[key] = value
                    }
                }
            }
            return _otherdeath
        },
        complaint () {
            let _complaint = {}
            if ('OfficialComplaintInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.OfficialComplaintInfo)) {
                    if (value !== "nan") {
                        _complaint[key] = value
                    }
                }
            }    
            return _complaint
        },
        autopsy () {
            let _autopsy = {}
            if ('AutopsyToxicolAnalInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.AutopsyToxicolAnalInfo)) {
                    if (value !== "nan") {
                        _autopsy[key] = value
                    }
                }
            }
            return _autopsy
        },
        toxinsused () {
            let _toxinsused = {}
            if ('ToxinsUsed' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.ToxinsUsed)) {
                    if (value !== "nan") {
                        _toxinsused[key] = value
                    }
                }
            }
            return _toxinsused
        },
        gencom () {
            let _gencom = {}
            if ('GeneralComments' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.GeneralComments)) {
                    if (value !== "nan") {
                        _gencom[key] = value
                    }
                }
            }
            return _gencom
        },
        extra () {
            let _extra = {}
            if ('ExtraPublicInfo' in this.incident.data) {
                for (let [key,value] of Object.entries(this.incident.data.ExtraPublicInfo)) {
                    if (value !== "nan") {
                        _extra[key] = value
                    }
                }
            }
            return _extra
        },

        images () {
            let _images = []
            if ('Images' in this.incident.data) {
                _images = this.incident.data.Images.images
            }
            if (_images.length > 0) {
                let _media = []
                for (let i=0; i != _images.length; i ++ ) {
                    let tmp = _images[i]
                    _media.push({
                        thumb: process.env.VUE_APP_BASE_URL + '/static' + tmp.thumbpath,
                        src: process.env.VUE_APP_BASE_URL + '/static' + tmp.filepath,
                        caption: (tmp.description ? tmp.description : 'n/a'),
                        tags: (tmp.tags ? tmp.tags : null),
                        id: tmp.id
                    })
                }
                return _media
            } else {
                return []
            }
        }
    },
    methods: {
        enabler (e) {
            if (e === 'foo') {
                this.foodit = true
            }
        },
        disabler (e) {
            if (e === 'foo') {
                this.foodit = false
            }
        },
        informantlink () {
            if (this.countrycheck) {
                this.$router.push({name:'Informant', params: this.entrycode})
            }
        },
        notificationlink () {
            if (this.countrycheck) {
                this.$router.push({name:'NotificationInfo', params: this.entrycode})
            }
        },
        poisonlink () {
            if (this.countrycheck) {
                this.$router.push({name:'PoisonEvent', params: this.entrycode})
            }
        },
        deadanimallink () {
            if (this.countrycheck) {
                this.$router.push({name:'DeadAnimalFound', params: this.entrycode})
            }
        },
        aliveanimallink () {
            if (this.countrycheck) {
                this.$router.push({name:'AliveAnimalFound', params: this.entrycode})
            }
        },
        poisonbaitlink () {
            if (this.countrycheck) {
                this.$router.push({name:'PoisonBaitInfo', params: this.entrycode})
            }
        },
        evidencelink () {
            if (this.countrycheck) {
                this.$router.push({name:'EvidenceInfo', params: this.entrycode})
            }
        },
        motivelink () {
            if (this.countrycheck) {
                this.$router.push({name:'Motive', params: this.entrycode})
            }
        },
        authoritylink () {
            if (this.countrycheck) {
                this.$router.push({name:'AuthoritiesInv', params: this.entrycode})
            }
        },
        antidoglink () {
            if (this.countrycheck) {
                this.$router.push({name:'AntiDogUnit', params: this.entrycode})
            }
        },
        sampleslink () {
            if (this.countrycheck) {
                this.$router.push({name:'SamplesCol', params: this.entrycode})
            }
        },
        disposallink () {
            if (this.countrycheck) {
                this.$router.push({name:'DisposalDead', params: this.entrycode})
            }
        },
        otherreasonslink () {
            if (this.countrycheck) {
                this.$router.push({name:'OtherReasons', params: this.entrycode})
            }
        },
        officialcomplink () {
            if (this.countrycheck) {
                this.$router.push({name:'OfficialComp', params: this.entrycode})
            }
        },
        autopsylink () {
            if (this.countrycheck) {
                this.$router.push({name:'Autopsy', params: this.entrycode})
            }
        },
        toxinslink () {
            if (this.countrycheck) {
                this.$router.push({name:'Toxins Used', params: this.entrycode})
            }
        },
        gencommentslink () {
            if (this.countrycheck) {
                this.$router.push({name:'GenComments', params: this.entrycode})
            }
        },
        extrainfolink () {
            if (this.countrycheck) {
                this.$router.push({name:'ExtraInfo', params: this.entrycode})
            }
        },
        imageslink () {
            if (this.images) {
                this.$router.push({name:'Images', params: this.entrycode})
            }
        }
        
    }

}
</script>


<style scoped>
.cardtitle {
   background-color: #7eb09f;   
}
</style>